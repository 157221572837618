import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-table/react-table.css';
import 'react-image-lightbox/style.css';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

let render = () => {
  import('./assets/css/sass/default.scss').then((x) => {
    require('./AppRenderer');
  });
};
render();
