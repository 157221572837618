import {combineReducers} from 'redux';
import menu from './menu/reducer';
import user from './login/reducer';

const reducers = combineReducers({
  menu,
  user,
});

export default reducers;
